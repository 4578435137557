/*
 *
 */
import * as React from 'react';
import Page from '../components/page';
import Button from '../components/button';

/*
 *
 */
const PageContent = () => {
	return (
		<Page
			title="La consultation individuelle pour adulte"
			intro="Toute personne peut un jour ou l’autre sentir qu’elle a besoin d’être aidée, de parler, de déposer sa souffrance ou tout simplement d’être entendue."
			content={(
				<React.Fragment>
					<p>
						Que ce soit pour des difficultés liées au passé ou au présent, allant d’un inconfort à un mal-être plus profond, la consultation individuelle vous ouvre un espace de parole non jugeant dans un cadre sécurisant couvert par le secret professionnel.
					</p>

					<p>
						La consultation individuelle peut vous aider si vous êtes angoissé(e), stressé(e) ou si vous vivez des situations difficiles à surmonter liés à un deuil, une séparation, à des difficultés relationnelles dans la vie privée ou professionnelle, si vous vous sentez épuisé(e), déprimé(e), vide ou abandonné(e), que vous souhaitez mieux vous comprendre...
					</p>

					<p>
						Les consultations ont lieu généralement une à deux fois par semaine selon votre situation. Ceci se décide, bien sûr, de commun accord.
					</p>

					<p>
						Les consultations se déroulent en face à face.
					</p>

					<p>
						Même si l’écoute est centrale dans la consultation, je vous aiderai, dans le plus grand respect de vos convictions personnelles, à élaborer votre pensée et vous soutiendrai dans votre recherche de compréhension, de sens et à (re)trouver vos propres ressources.
					</p>

					<br />
					<Button to="/rendez-vous/" label="Prendre un rendez-vous" />
				</React.Fragment>
			)}
		>
			<title>La consultation pour adulte — Isabelle Kopp</title>
		</Page>
	);
};

export default PageContent;
